import { useState } from "react";
import { Button } from "@presentation/shared/components/button/Button";
import { FiCheckSquare, FiSquare } from "react-icons/fi";
import { useService } from "@infrastructure/api/hooks/use-service";
import { Requests } from "@infrastructure/api/requests";

const questions = [
  {
    question: "A text for the question?",
    choices: ["Answer 1", "Answer 2", "Answer 3"],
  },
  {
    question: "Another question?",
    choices: ["Another Answer 1", "Another Answer 2", "Another Answer 3"],
  },
];

export function RegistrationForm() {
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [answers, setAnswers] = useState(questions.map(() => null));

  const { question, choices } = questions?.[currentQuestion] ?? {};

  const chooseAnswer = (q, ch) => {
    setAnswers(answers.map((a, i) => (i === q ? ch : a)));
  };

  const onSubmit = () => {
    if (answers[currentQuestion] !== null) {
      setCurrentQuestion((q) => q + 1);
    }
  };

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const isValidForm = Boolean(firstName && lastName && email);

  const { loading, request } = useService(Requests.saveGiveAway);
  console.log({ loading, request });

  return (
    <div className="bg-white w-full md:w-auto md:rounded-3xl flex flex-col">
      {currentQuestion < questions.length ? (
        <div className="px-10 py-6">
          <div className="text-3xl">
            Answer the following {questions.length} questions
          </div>
          <div>
            <div className="text-xl font-bold pt-6 pb-3">
              {currentQuestion + 1}. {question}
            </div>
            {choices.map((choice, idx) => {
              const isChecked = answers[currentQuestion] === idx;
              return (
                <div
                  className="my-3 text-lg flex items-center cursor-pointer"
                  key={idx}
                  onClick={() => chooseAnswer(currentQuestion, idx)}
                >
                  <span className="mr-4">
                    {isChecked ? <FiCheckSquare /> : <FiSquare />}
                  </span>
                  <span>{choice}</span>
                </div>
              );
            })}
          </div>
          <div className="py-6 text-right">
            <Button
              onClick={onSubmit}
              disabled={answers[currentQuestion] === null}
            >
              Next
            </Button>
          </div>
        </div>
      ) : (
        <div className="px-10 py-6">
          <div className="text-2xl mb-6">Enter your details</div>
          <div className="table">
            <div className="table-row">
              <label
                className="text-right table-cell pr-4 whitespace-nowrap"
                htmlFor="firstName"
              >
                First Name*
              </label>
              <input
                className="table-cell min-w-[300px] my-3"
                name="firstName"
                type="text"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
              />
            </div>
            <div className="table-row">
              <label
                className="text-right table-cell pr-4 whitespace-nowrap"
                htmlFor="lastName"
              >
                Last Name*
              </label>
              <input
                className="table-cell min-w-[300px] my-3"
                name="lastName"
                type="text"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
              />
            </div>
            <div className="table-row">
              <label
                className="text-right table-cell pr-4 whitespace-nowrap"
                htmlFor="firstName"
              >
                Phone Number*
              </label>
              <input
                className="table-cell min-w-[300px] my-3"
                name="phone"
                type="text"
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
              />
            </div>
            <div className="table-row">
              <label
                className="text-right table-cell pr-4 whitespace-nowrap"
                htmlFor="email"
              >
                Email Address
              </label>
              <input
                className="table-cell min-w-[300px] my-3"
                name="email"
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <div className="table-row">
              <div className="table-cell" />
              <div className="table-cell text-right">
                <Button onClick={onSubmit} disabled={!isValidForm}>
                  Submit
                </Button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
